import React from 'react';


/**
 * EmailToTags
 * @param {boolean} - [props.isSign] if X button is needed 
 * @param {Array} - [props.Catalogs] list of Catalogs
 * @return tags from the list of the catalog
 */
const EmailToTags = (props) =>
{
    if(props.Emails !== undefined)
    {
        let member = [];
        for(let i = 0;i<props.Emails.length;i++)
        {
            member.push(<React.Fragment key={i}><span className="tag is-rounded is-black has-text-success-light">{props.Emails[i]}</span><br/></React.Fragment>);
        }
        return (<div  className="custom-scrollbar"  style={{"overflowY":"scroll","height" : "71%"}}>
                    {member}
                </div>);
    }
    return <></>;
}
export default EmailToTags;