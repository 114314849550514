let Nodeurl = "";
let currUrl = ""
let authURL = "";
let pythonURL = "";
let pythonURLQC = "";
let path = "";

if(window.location.href.includes("localhost"))
{
    Nodeurl = "http://localhost:8090"
    currUrl = "http://localhost:3002"
    authURL = "http://localhost:3001"
    pythonURL = "http://127.0.0.1:5100"
    pythonURLQC = "http://127.0.0.1:5100"
    path = "C:/Users/Gad/Documents/GitHub/try/Files"
}
else
{
     Nodeurl = "https://api.bademeister-jan.pro:453"
     currUrl = "https://admin.bitsonic.ai/"
     authURL = "https://auth.bitsonic.ai/"
     pythonURL = "https://api.bademeister-jan.pro:336"
     pythonURLQC = "https://api.bademeister-jan.pro:338"
     path = "/mnt/disks/birddata2/birddata"
}

export const DynamicClients = ['Dynamic BETA','Client','Dynamic Music'];

export {Nodeurl,currUrl,authURL,pythonURL,path,pythonURLQC};