import React from 'react';
import { useDispatch} from "react-redux";
import {setClientDictShowALL} from "../../redux/client";

/**
 * ClientComponent
 * @param {string} - [props.Name] client name 
 * @param {Array} - [props.Catalogs] array of catalogs
 * @param {string} - [props.Email] client email
 * @param {string} - [props.ClientLogo] client logo
 * @return client component(menu li)
 */
const ClientComponent = (props) =>
{
    const dispatch = useDispatch();
    
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return (<><br/><li><a className={props.isAdmin ? 'has-background-success has-text-white' : 'has-background-black-ter has-text-white'} onClick={() => {dispatch(setClientDictShowALL({"Name" : props.Name,"Catalogs" : props.Catalogs,"Email" : props.Email,"Logo" : props.ClientLogo,"Packs" : props.Packs,"isAdmin" : props.isAdmin,"bitSonicAccount" : props.bitSonicAccount,"catalogStatus" : props.catalogStatus}));}}>{props.Name}</a></li></>);
}

export default ClientComponent;