
import React, { useState,useEffect } from 'react';
import AlertMsg from './AlertMsg';
import ClientComponent from './ClientComponent';
import {ChangeClientDictShow, changeModal,setClientDictShowALL,setClientDictSignALL} from "../../redux/client";
import { useSelector,useDispatch } from "react-redux";
import UserModal from './UserModal';
import CatalogToTags from "./CatalogToTags"
import EmailToTags from './EmailToTags';
import PacksToTags from './PacksToTags';
import { currUrl, Nodeurl,authURL, DynamicClients, pythonURL, } from './variables';
import {useCookies} from 'react-cookie'
import { GenerateModal } from './GenerateModal';
/**
 * MainComponent
 * @return all the screen
 */
function getInJson(dict,key,defaultVal)
{
    if (key in dict)
        return dict[key];
    else
        return defaultVal;
}
export const sendDateGetSuccess = async(action,body,cookies,setCookie) =>
{
    let mydata;
    await fetch(Nodeurl+"/"+action+"Client", {
        method: 'post',
        headers: {'Content-Type': 'application/json',"authorization" : cookies.jwt},
        body: body
    })
    .then(async (data) => 
    {
        if(data.status === 403)
            throw new Error('Whoops!');
        else if (data.status === 401)
        {
            alert("Time has passed with no action, re-login required");
            setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            window.location.href = authURL; 
        }
        data = await data.json();
        setCookie("jwt",data.jwt,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
        mydata = data;
    })
    .catch(error => {
        console.error('Error:', error);
    });
    return mydata;

}

const MainComponent = ({currtab,index}) =>
{
    const { modal } = useSelector((state) => state.client);
    const [alertMsg,setAlertMsg] = useState(false);
    const [clients, setClients] = useState([]);
    const {clientDictShow} = useSelector((state) => state.client);
    const {clientDictSign} = useSelector((state) => state.client);
    const [cookies, setCookie, ] = useCookies(['redirection',"jwt"]);
    const [isLoading, ] = useState("");
    const [generateModal, setGenerateModal] = useState("");
    const [generateForecast, setGenerateForecast] = useState("");

    const dispatch = useDispatch();

    const getClient = async () =>
    {
        await fetch (Nodeurl+"/GetClients",{headers: {"authorization" : cookies.jwt}})
        .then(async (data) => 
        {
            if(data.status === 403)
                throw new Error('Whoops!');
            else if (data.status === 401)
            {
                setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
                window.location.href = authURL; 
            }
            else 
            {
                data = await data.json();
                setCookie("jwt",data.jwt,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
                data = JSON.parse(data.data);
                let bitSonic = [];
                let otherClients = [];

                setClients([]);
                data = Object.values(data);
                data = data.sort((a, b) => a["clientName"].localeCompare(b["clientName"]))
                for(let i = 0;i < data.length;i++)
                {
                    if(getInJson(data[i],"bitSonicAccount",false))
                        bitSonic.push(<React.Fragment key={i}><ClientComponent Name={data[i]["clientName"]} Catalogs={data[i]["Catalogs"]} Users={data[i]["Users"]} Email={data[i]["clientEmail"]} ClientLogo={data[i]["clientLogo"]} Packs={data[i]["Packs"]} isAdmin={getInJson(data[i],"isAdmin",false)} bitSonicAccount={getInJson(data[i],"bitSonicAccount",false)} catalogStatus={data[i]["catalogStatus"]}/></React.Fragment>);
                    else
                        otherClients.push(<React.Fragment key={i}><ClientComponent Name={data[i]["clientName"]} Catalogs={data[i]["Catalogs"]} Users={data[i]["Users"]} Email={data[i]["clientEmail"]} ClientLogo={data[i]["clientLogo"]} Packs={data[i]["Packs"]} isAdmin={getInJson(data[i],"isAdmin",false)} bitSonicAccount={getInJson(data[i],"bitSonicAccount",false)} catalogStatus={data[i]["catalogStatus"]}/> </React.Fragment>);
                }
                setClients
                (<>
                    <div>
                        <center><p className="menu-label mb-0 mt-4">Internal Bitsonic A/C</p></center>
                        {bitSonic}
                    </div>
                    <div>
                        <center><p className="menu-label mb-0 mt-4">Other Clients</p></center>
                        {otherClients}
                    </div>
                
                </>);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setAlertMsg(true);
            console.log("error " + error);
        });
    }
    const addClick = () =>
    {
        dispatch(setClientDictSignALL({"Name" : "Client","Catalogs" : "","Logo" : "","Email" : [],"Packs" : "","isAdmin" : false,"bitSonicAccount" : false}));
        dispatch(ChangeClientDictShow(["Catalogs",[]]));   
        dispatch(ChangeClientDictShow(["Packs",[]]));
        dispatch(changeModal("Add"));
    }
    const delClick = async () =>
    {
        if(clientDictShow["Name"] !== "" && clientDictShow["Name"] !== undefined)
        {
            const data = await sendDateGetSuccess("Del",JSON.stringify({"clientName": clientDictShow["Name"],"clientEmail":clientDictShow["Email"] }),cookies,setCookie);
            if(data["result"]==="Success")
                getClient();
            dispatch(setClientDictShowALL({}));
        }
        else
            alert("Choose user");
    }
    const updateClick = () =>
    {
        if(clientDictShow["Name"] !== "" && clientDictShow["Name"] !== undefined)
        {
            dispatch(setClientDictSignALL({"Name" : clientDictShow["Name"],"Catalogs" : "","Logo" : clientDictShow["Logo"],"Email" : clientDictShow["Email"],"Packs" : "","isAdmin" : clientDictShow["isAdmin"],"bitSonicAccount" : clientDictShow["bitSonicAccount"]}));
            dispatch(changeModal("Update"));
        }
        else
            alert("Choose user");
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getClient(); }, []);

    useEffect(() => 
    {
        const func = async () =>
        {
            let modalAction = modal.split(',');
            if(modalAction[0]==="Done")
            {
                if(clientDictSign["Name"] !== "" && clientDictShow["Catalogs"].length > 0 && clientDictSign["Email"].length > 0)
                {
                    dispatch(changeModal(""));
                    let data;
                    if(modalAction[1]==="Update")
                        data = await sendDateGetSuccess("Update",JSON.stringify({new : {"clientName":clientDictSign["Name"],'Catalogs': clientDictShow["Catalogs"],"clientEmail" : clientDictSign["Email"],"clientLogo" : clientDictSign["Logo"],"Packs" : clientDictShow["Packs"],"isAdmin" : clientDictSign["isAdmin"],"bitSonicAccount" : clientDictSign["bitSonicAccount"],"catalogStatus" : clientDictShow["catalogStatus"]}, old : {"clientName":clientDictShow["Name"],"clientEmail" : clientDictShow["Email"],"clientLogo" :clientDictShow["Logo"]}}),cookies,setCookie);
                    else if (modalAction[1]==="Add")
                        data = await sendDateGetSuccess("Add",JSON.stringify({"clientName":clientDictSign["Name"],'Catalogs': clientDictShow["Catalogs"] ,"clientEmail" : clientDictSign["Email"],"clientLogo" : clientDictSign["Logo"],"Packs" : clientDictShow["Packs"],"isAdmin" : clientDictSign["isAdmin"],"bitSonicAccount" : clientDictSign["bitSonicAccount"]}),cookies,setCookie);
                    
                    if(data["result"]==="Success")
                        getClient();


                    dispatch(setClientDictShowALL({}));
                }
                else
                {
                    alert("Empty Fields");
                    dispatch(changeModal(modalAction[1]));
                }
            }
        }
        func();
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[modal]);

    const generateForecastReports = async () =>
    {
        await fetch (pythonURL+"/GenerateForecastReports",
        {
            method: 'post',
            headers: {"authorization" : cookies.jwt},
            body: new URLSearchParams({
                "Name" : clientDictShow["Name"],
                "catalogStatus" : JSON.stringify(clientDictShow["catalogStatus"]),
            })
        })
        .then(async (data) => 
        {
            if(data.status === 403)
                throw new Error('Whoops!');
            else if (data.status === 401)
            {
                setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
                window.location.href = authURL; 
            }
            if(data.status === 400)
                alert(data['result']);
            else 
            {
                data = await data.json();
                alert(data['result'])
            }
            setGenerateForecast("");
        })
        .catch(error => {
            console.error('Error:', error);
            setAlertMsg(true);
            console.log("error " + error);
        });
        
    }

    return  (
        <>

            {currtab === index ? 
            <>
                <GenerateModal generateModal={generateModal} setGenerateModal={setGenerateModal}/>
                <div className='columns fullHeight' style={{"zoom":"125%","height": "90%"}}>
                <div className='column '>
                    <h1 className="title has-text-white-bis m-0"><center>Clients</center></h1>
                    <div  className="custom-scrollbar"  style={{"overflowY":"scroll","height": "90%"}}>
                    <aside className="menu">
                        <ul className="menu-list " >
                        {clients}
                        </ul>
                    </aside>
                    </div>
                </div>
                <div><hr className='hrdesign'/></div>
                <div className='column is-two-thirds'>
                    <div className='fullHeight'>
                        <div className='fullHeight' style={{"padding" : "0px" }}>
                            <div className='columns is-centered is-vcentered m-0 p-0'>
                                <div className='column m-0 p-0'>
                                    <h1 className="title has-text-white-bis mb-2"><center>Information:</center></h1>
                                </div>
                                <div className='column m-0 p-0'>
                                    <center>
                                        <button className={'button is-small has-background-dark has-text-white ' + isLoading} onClick={() => setGenerateModal("is-active")} disabled={clientDictShow["Name"] === "" || clientDictShow["Name"] === undefined}>Generate "ALL" output</button>
                                        {DynamicClients.includes(clientDictShow['Name']) ? 
                                            <button className={'button ml-2 is-small has-background-dark has-text-white ' + generateForecast}
                                                    disabled={clientDictShow["Name"] === "" || clientDictShow["Name"] === undefined}
                                                    onClick={() => {generateForecastReports();setGenerateForecast("is-loading")}}>
                                                Generate Forecast reports
                                            </button>
                                        : null }
                                    </center>
                                </div>
                            </div>
                            <div className='columns mb-1' style={{"height" : "55%" }}>
                                <div className='column'>
                                    <p className="has-text-warning">Client Name: <br/><span className='has-text-white-bis'>{clientDictShow["Name"]}</span></p>
                                    <p className="has-text-warning">Users: <br/></p>
                                    <span className='has-text-white-bis'>{<EmailToTags Emails={clientDictShow["Email"]} />}</span>
                                </div>
                                <div className='column mb-2'>
                                    <center style={{"height" : "90%" }}>
                                        <div><span className="has-text-warning">Catalogues: </span><span className='has-text-warning is-pulled-right'>Owned</span></div>
                                        {<CatalogToTags Catalogs={clientDictShow["Catalogs"]} isSign={false}/>}
                                        <p className="has-text-warning">Output Packs: </p>
                                        {<PacksToTags Packs={clientDictShow["Packs"]} isSign={false}/>}
                                    </center>
                                </div>
                            </div>
                            <div style={{"height" : "27%" }}>
                                <center style={{"height" : "100%" }}><p className="has-text-warning">Client Logo: </p><div><img src={clientDictShow["Logo"]} alt=''></img></div></center>
                                <div className='buttons is-centered'>
                                    <button className="button is-success is-rounded is-outlined" onClick={() =>addClick()}>+</button>
                                    <button className="button is-danger is-rounded is-outlined" onClick={() => delClick()}>-</button>
                                    <button className="button is-info is-rounded is-outlined" onClick={() => updateClick()}>🗘</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <UserModal />
                {alertMsg ? <AlertMsg /> : <></>}
            </>
            : <></>}
        </>)
        
}

export default MainComponent;