import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {changeModal,ChangeClientDictSign,setClientDictShowALL,setActionToDo} from "../../redux/client";
import DropDownSearch from "./DropDownSearch"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
/**
 * validateEmail
 * @param {string} - [email] email to check validate
 * @return checking validateEmail with regex
 */

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * UserModal
 * @return User Model to update or add client
 */

const UserModal = () =>
{  
    const dispatch = useDispatch();

    const { modal } = useSelector((state) => state.client);

    const { clientDictSign } = useSelector((state) => state.client);
    const [someWrong,] = useState(false);
    const [isEnabled,setEnabled] = useState(true)
    let classModal = modal !== "" ? "modal is-active" : "modal"
    const uploadIcon = (e) =>
    {
      setEnabled(false);
      let thefile = e.currentTarget.files[0]
      if (thefile !== undefined)
      {
        const data = new FormData()
        data.append("file", thefile)
        data.append("upload_preset", "ibsbcrbh")
        data.append("cloud_name","bitsonic")

        fetch("https://api.cloudinary.com/v1_1/bitsonic/image/upload",
        {method:"post",body: data}).then(resp => resp.json()).then(data => 
        {
          dispatch(ChangeClientDictSign(["Logo",data.secure_url.substring(0,48)+"/h_100,c_scale/"+data.secure_url.substring(48)]));
          setEnabled(true);
        })
        .catch(err => console.log(err))
      }
      else
       setEnabled(true);
    }
    const CloseModal = () =>
    {
        dispatch(setClientDictShowALL({}));
        dispatch(changeModal(""));
    }
    const tagChange = (tags) =>
    {
      if(emailRegex.test(String(tags.slice(-1)[0]).toLowerCase().trim()) || tags.length === 0)
        dispatch(ChangeClientDictSign(["Email",[...new Set(tags.map((l) => l.toLowerCase().trim()))]]))
    }
    return(<div className={classModal}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Enter: </p>
            <button className="delete" aria-label="close" onClick={() =>CloseModal()}></button>
          </header>
          <section className="modal-card-body" >
            <div className="field">
              <div className="control">
                <div className='columns is-vcentered'>
                    <div className='column'>
                      <h1 className='title is-4 mb-2'>Client Name: </h1><input className="input" type="text" placeholder="Enter Name" onChange={(e) => dispatch(ChangeClientDictSign(["Name",e.target.value]))} value={clientDictSign["Name"]}/><br/><br/>
                    </div>
                    <div className='column is-narrow'>
                        <div className="field">
                            <input id="adminSwitch" type="checkbox" name="switchExample" className="switch" checked={clientDictSign["isAdmin"]} onChange={(e) => dispatch(ChangeClientDictSign(["isAdmin",e.currentTarget.checked]))} />
                            <label htmlFor="adminSwitch">Admin</label>
                        </div>
                        <div className="field">
                            <input id="BitSonicSwitch" type="checkbox" name="BitSonicExample" className="switch is-info" checked={clientDictSign["bitSonicAccount"]} onChange={(e) => dispatch(ChangeClientDictSign(["bitSonicAccount",e.currentTarget.checked]))} />
                            <label htmlFor="BitSonicSwitch">Internal Bitsonic A/C</label>
                        </div>
                    </div>

                </div>
                <h1 className='title is-4 mb-2'>Users: </h1><TagsInput inputProps={{placeholder : "Enter email and then press enter",style : {"width" : "100%"}}} value={clientDictSign["Email"] !== undefined ? clientDictSign["Email"] : []} onChange={(e) => tagChange(e)}/><br/>
                <h1 className='title is-4 mb-2'>Catalogues: </h1><input className="input" type="text" placeholder="Enter Catalogs" onBlur={() => {dispatch(setActionToDo("CloseCataContant"))}} onFocus={() => dispatch(setActionToDo("OpenCataContant"))} onChange={(e) => {dispatch(ChangeClientDictSign(["Catalogs",e.target.value]));dispatch(setActionToDo("ListUpdateCatalogs"));}} value={clientDictSign["Catalogs"]}/><br/><DropDownSearch Kind={"Catalogs"}/><br/>
                <h1 className='title is-4 mb-2'>Output Packs: </h1><input className="input" type="text" placeholder="Enter Packs" onBlur={() => {dispatch(setActionToDo("ClosePackContant"))}} onFocus={() => dispatch(setActionToDo("OpenPackContant"))} onChange={(e) => {dispatch(ChangeClientDictSign(["Packs",e.target.value]));dispatch(setActionToDo("ListUpdatePacks"));}} value={clientDictSign["Packs"]}/><br/><DropDownSearch Kind={"Packs"}/><br/>
                <h1 className='title is-4'>Logo: </h1>
                <div className='columns'>
                  <div className="column">
                    <div className='file is-primary has-name is-boxed is-fullwidth is-size-2'>
                      <label className="file-label has-text-centered"><input className="file-input" type="file" name="resume" accept="image/*" onClick={(e) => {e.currentTarget.value = null}} onChange={(e) => uploadIcon(e)}/>
                        <span className="file-cta"><span className="file-icon "><i className="fas fa-upload"></i>&nbsp;Add Logo</span></span>
                      </label>
                      <br/>
                    </div>
                    <br/>
                  </div>
                  <div className='column'><img alt='' src={clientDictSign["Logo"]}/></div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className={(!isEnabled || someWrong) ? "button is-danger" : "button is-success"} title={!isEnabled ? "Image Updated please wait..." : (someWrong ? "Email Invalid" :"")} onClick={() => dispatch(changeModal("Done,"+modal))} data-tooltip="Tooltip Text"  disabled={!isEnabled || someWrong}> Save changes</button>
            <button className="button" onClick={() => CloseModal()}>Cancel</button>
          </footer>
        </div>
      </div>);
}

export default UserModal;