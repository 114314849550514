import React from 'react';
import {deleteIndexPack} from "../../redux/client";
import { useDispatch} from "react-redux";


const PacksToTags = (props) =>
{
    const dispatch = useDispatch();

    if(props.Packs !== undefined)
    {
        let member = [];
        for(let i = 0;i<props.Packs.length;i++)
        {
            member.push(<React.Fragment key={i}><span className="tag is-rounded is-black has-text-danger-light">{props.isSign ? <button className="delete is-small" onClick={(e) =>  {dispatch(deleteIndexPack(e.target.parentElement.innerText));}}/>:<></>}{props.Packs[i]}</span></React.Fragment>);
        }
        return (<div  className="custom-scrollbar"  style={{"overflowY":"scroll","height" : "33%"}}>
                    {member}
                </div>);
    }
    return <></>;
}
export default PacksToTags;