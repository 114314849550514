import React,{ useEffect, useState } from "react";
import { pythonURL } from "../ClientComponenet/variables";
import { useCookies } from "react-cookie";


const MenuButton = ({name,setIndex,userIndex,i,isAdmin}) =>
{
    console.log(userIndex)
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return(<li className="mb-4"><a className={(isAdmin ? "has-background-success threeDots  " : "has-background-black-ter threeDots ")+(userIndex === i ? "has-text-link" : "has-text-white")} onClick={() => setIndex(i)}><b>{name}</b></a></li>)
}
const CatalogsButton = ({name}) =>
{
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return(<span className="tag is-small has-background-black-ter has-text-white " style={{"width" : "100%","justifyContent" : "start"}}>{name}</span>
)
}
const UsersComp = ({currtab,index}) =>
{
    const [users,setUsers] = useState([]);
    const [catalogs,setCatalogs] = useState([]);
    const [data,setData] = useState();

    const [userIndex,setUserIndex] = useState(-1);
    const [cookies, , ] = useCookies(['redirection',"jwt"]);

    const create_users = (data) =>
    {
        let ary = [];
        console.log(data)
        const datakeys = Object.keys(data.data);
        let catalogAry = [];
        for(let i=0;i<datakeys.length;i++)
        {
            ary.push(<React.Fragment key={i}><MenuButton userIndex={userIndex} isAdmin={data.data[datakeys[i]].isAdmin} name={datakeys[i]} setIndex={setUserIndex} i={i}/></React.Fragment>)
            catalogAry.push(data.data[datakeys[i]].Catalogs.map((name,f) => <React.Fragment key={f}><CatalogsButton name={name} /></React.Fragment>));
        }
        setUsers(ary)
        setCatalogs(catalogAry);
    }

    useEffect(() =>
    {   
        const getUsersData = async () =>
        {
            await fetch (pythonURL+"/GetUsersData/",{method: "get",headers: {"authorization" : cookies.jwt}})
            .then(async (data) => 
            {
                data = await data.json();
                console.log(data)
                setData(data);
                create_users(data);
            })
            .catch(error => {console.log("error " + error);});
        }
        getUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {if(data) create_users(data)},[userIndex])
    return (
    <>
        {currtab === index ? 
        <div className="columns" style={{"zoom":"125%","height": "90%"}}>
            <div className="column">
                <h1 className="title has-text-white-bis"><center>Users ({users?.length})</center></h1>
                <div  className="custom-scrollbar"  style={{"overflowY":"scroll","height": "90%"}}>
                    <ul className="menu-list ">
                        {users.length > 0 ? users : <center><div className="loaderCss"></div></center>}
                    </ul>
                </div>
            </div>
            <div><hr className='hrdesign'/></div>
            <div className="column">
                <h1 className="title has-text-white-bis"><center>{"Catalogs " + (catalogs[userIndex] !== undefined ? "("+catalogs[userIndex]?.length+")" : "")} </center></h1>
                <div  style={{"overflowY":"scroll","height": "90%"}}>
                    {catalogs[userIndex]}
                </div>
            </div>
        </div>
         :
        <></>}
    </>);
}

export default UsersComp;