import React from 'react';
import {ChangeClientDictShow, changeModal, deleteIndexCatalog, setClientDictSignALL} from "../../redux/client";
import { useDispatch, useSelector} from "react-redux";


const owneddict = {"Owned" : "owned",'Due Diligence (Live)' : "",'Due Diligence (Archive)' : "archive"}

const CheckBoxLabel = ({index}) =>
{
    const { clientDictShow } = useSelector((state) => state.client);
    const dispatch = useDispatch();

    const setOwnedCatalog = async (e) =>
    {
        let catalogStatus = {...clientDictShow["catalogStatus"]};
        catalogStatus[clientDictShow["Catalogs"][index]] = owneddict[e.currentTarget.value];
        dispatch(ChangeClientDictShow(["catalogStatus",{...catalogStatus}]));
        dispatch(setClientDictSignALL({"clientName":clientDictShow["Name"],"Email" : clientDictShow["Email"],"Catalogs" : clientDictShow["Catalogs"]}));
        dispatch(changeModal("Done,Update"))
    }

    return (
        <>
           <div class="control is-small mb-1">
                <div class="select is-small background-dark has-text-white">
                    <select onChange={(e) => setOwnedCatalog(e)}>
                        {
                        ["Owned",'Due Diligence (Live)','Due Diligence (Archive)'].map((e,i) => {
                            return <React.Fragment key={i}>
                                        <option selected={owneddict[e] === clientDictShow["catalogStatus"][clientDictShow["Catalogs"][index]]}>{e}</option>
                                    </React.Fragment>
                        })
                        }
                    </select>
                </div>
            </div>
        </>
    )
}

const CatalogToTags = (props) =>
{
    const dispatch = useDispatch();

    if(props.Catalogs !== undefined)
    {
        let member = [];

        for(let i = 0;i<props.Catalogs.length;i++)
        {
            if(props.isSign)
                member.push(<React.Fragment key={i}>
                    <span className="tag is-rounded is-black has-text-danger-light">
                        <button className="delete is-small" onClick={(e) =>  {dispatch(deleteIndexCatalog(e.target.parentElement.innerText));}}/>
                        {props.Catalogs[i]}
                    </span></React.Fragment>);
            else
            {
                member.push(<React.Fragment key={i}>
                    <tr style={{'borderColor' : "hsl(0deg, 0%, 21%) !importent"}}>
                        <td className='m-0 pl-0 pb-0'><CheckBoxLabel index={i}/></td>
                        <td className='m-0 pr-0'><span className="tag is-rounded is-black has-text-danger-light" style={{"direction" : "ltr"}}>
                                                    {props.Catalogs[i].slice(0,40)}{props.Catalogs[i].length >= 40 ? "..." : ""}
                                                 </span></td>
                    </tr>
                    </React.Fragment>);
            }
                

        }
        return (<div  className="custom-scrollbar mb-2"  style={{"overflowY":"scroll","overflowX":"hidden","height" : "60%","zoom" : "90%"}}>
                    {
                        props.isSign ?
                            member
                        :
                            <div className='columns is-centered is-vcentered'>
                                <table class="table mt-1" style={{"backgroundColor" : "hsl(0deg, 0%, 21%)"}}>
                                    {member}
                                </table>

                            </div>

                    }
                    
                </div>);
    }
    return <></>;
}
export default CatalogToTags;