import React,{useState } from "react";
import { useSelector } from "react-redux";
import {useCookies} from 'react-cookie'
import { authURL, currUrl, pythonURL } from "./variables";

export const GenerateModal = ({generateModal,setGenerateModal}) =>
{
    const {clientDictShow} = useSelector((state) => state.client);
    const [cookies, setCookie, ] = useCookies(['redirection',"jwt"]);

    const [isLoading,setIsLoading] = useState("");
    const [formData,setFormData] = useState({"statement" : "statement","royalty" : "Rev"});
    const generateAllOutput = async () =>
    {
        setIsLoading("is-loading");
        if(clientDictShow["Name"] !== "" && clientDictShow["Name"] !== undefined)
        {
            await fetch(pythonURL+"/generateAllOutput", {
                method: 'post',
                headers: {"authorization" : cookies.jwt},
                body: new URLSearchParams({
                    "catalogStatus" : JSON.stringify(clientDictShow["catalogStatus"]),
                    "Name" : clientDictShow["Name"],
                    "statement" : formData["statement"],
                })
            })
            .then(async (data) =>   
            {
                if(data.status === 403)
                    throw new Error('Whoops!');
                else if (data.status === 401)
                {
                    alert("Time has passed with no action, re-login required");
                    setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
                    window.location.href = authURL; 
                }
                const dataHeaders = Object.fromEntries(data.headers);
                if(dataHeaders.jwt !== undefined)
                    setCookie("jwt",dataHeaders.jwt,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});

                data = await data.json();
                
                alert("success!");
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Data error");
            });
        }
        else
            alert("Choose User");
        
        setIsLoading("");
    }  
    return (<div className={"modal " + generateModal}>
                <div className="modal-background"></div>
                <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Generate ALL Output</p>
                    <button className="delete" aria-label="close" onClick={() => setGenerateModal("")}/>
                </header>
                <section className="modal-card-body">
                    <center>
                        <h1 className="subtitle m-2 p-0">Date</h1>
                        <div className="select">
                            <select value={formData["statement"]} onChange={(e) => setFormData({...formData,"statement" : e.currentTarget.value})}>
                                <option value={"statement"}>Statement</option>
                                <option value={"distribution"}>Cash Date</option>
                            </select>
                        </div>

                        <h1 className="subtitle m-2 p-0">Royalty</h1>
                        <div className="select">
                            <select value={formData["royalty"]} onChange={(e) => setFormData({...formData,"royalty" : e.currentTarget.value})}>
                                <option value={"Rev"}>Revenue</option>
                                <option value={"Gross"}>Gross Revenue</option>
                                <option value={"Bonus"}>Bonus</option>
                                <option value={"Plays"}>Plays Revenue</option>
                            </select>
                        </div>

                    </center>
                </section>
                <footer className="modal-card-foot buttons is-centered">
                    <div>
                        <button className="button" onClick={() => setGenerateModal("")}>Cancel</button>
                        <button className={"button is-success " + isLoading} onClick={() => generateAllOutput()}>Save changes</button>
                    </div>
                </footer>
                </div>
            </div>);
}