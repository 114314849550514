import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "client",
  initialState:
  {
    clientDictShow : {"Email": [],"Name" : "","Catalogs" : [],"Packs" : [],"isAdmin" : false,"bitSonicAccount" : false,"catalogStatus" : {}},
    clientDictSign : {"Email": [],"Name" : "","Catalogs" : [],"Packs" : [],"isAdmin" : false,"bitSonicAccount" : false,"catalogStatus" : {}},
    modal : "",
    actionToDo : ""
  },
  reducers: {
    setClientDictShowALL(state, action)
    {
        state.clientDictShow = action.payload;
    },
    setClientDictSignALL(state, action)
    {
        state.clientDictSign = action.payload;
    },
    ChangeClientDictShow(state, action)
    {
      state.clientDictShow[action.payload[0]] = action.payload[1];
    },
    ChangeClientDictSign(state, action)
    {
        state.clientDictSign[action.payload[0]] = action.payload[1];
    },
    AddClientDictShow(state, action)
    {
        state.clientDictShow[action.payload[0]].push(action.payload[1]);
    },
    deleteIndexCatalog(state,action)
    {
      let index = state.clientDictShow["Catalogs"].indexOf(action.payload);
      state.clientDictShow["Catalogs"].splice(index, 1);
    },
    deleteIndexPack(state,action)
    {
      let index = state.clientDictShow["Packs"].indexOf(action.payload);
      state.clientDictShow["Packs"].splice(index, 1);
    },
    changeModal(state,action)
    {
      state.modal = action.payload;
    },
    setActionToDo(state,action)
    {
      state.actionToDo = action.payload;
    }
  }
});

export const {changeModal,setClientDictShowALL,ChangeClientDictShow,ChangeClientDictSign,setClientDictSignALL,AddClientDictShow,deleteIndexCatalog,deleteIndexPack,setActionToDo} = mySlice.actions;
export default mySlice.reducer;
