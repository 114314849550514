import React, { useState,useEffect } from 'react';
import {AddClientDictShow, ChangeClientDictSign, setActionToDo} from "../../redux/client";
import { useDispatch, useSelector } from "react-redux";
import CatalogToTags from "./CatalogToTags"
import PacksToTags from './PacksToTags';
import { pythonURL } from './variables';
import { useCookies } from 'react-cookie';

/**
 * DropDownSearch
 * @return drop down search to select catalog with the catalog list
 */
const DropDownSearch = (props) =>
{
  const [catalogList,setCatalogList] = useState("");
  const { clientDictShow } = useSelector((state) => state.client);
  const { clientDictSign } = useSelector((state) => state.client);
  const { actionToDo } = useSelector((state) => state.client);

  const [contantShowed,setcontantShowed] = useState(false);
  const [dropContentComp,setdropContentComp]= useState([]);
  const dispatch = useDispatch();
  const [isMouseover,SetIsMouseover] = useState(false);
  const [cookies, , ] = useCookies(['redirection',"jwt"]);

  
  useEffect(() => 
  { 
    if(props.Kind === "Catalogs")
    {
      fetch ("https://api.bademeister-jan.pro/project/list",{method:"post",})
      .then((response) => response.json()).then((data) => {setCatalogList(data.projects);})
      .catch(error => {});  
    }
    else
    {
      const getOutputPack = async () =>
      {
          await fetch (pythonURL+"/GetOutputPacks/",{method: "get",headers: {"authorization" : cookies.jwt}})
          .then(async (data) => 
          {
              data = await data.json()
              setCatalogList(data.packs);
          })
          .catch(error => {console.log("error " + error);});
      }
      getOutputPack();
      
    }

    listUpdate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const clickDropSearch = (e) =>
  {
    dispatch(AddClientDictShow([props.Kind,e.target.innerText]));
    dispatch(ChangeClientDictSign([props.Kind,""]));
    setcontantShowed(false);
    listUpdate();
  }
  useEffect(()=>
  {
    if(!isMouseover && (((actionToDo === "CloseCataContant" && props.Kind === "Catalogs") || (actionToDo === "ClosePackContant" && props.Kind === "Packs"))))
      {setcontantShowed(false);listUpdate();}
    if(!isMouseover && ((actionToDo === "OpenCataContant" && props.Kind === "Catalogs") || (actionToDo === "OpenPackContant" && props.Kind === "Packs")))
      {setcontantShowed(true);listUpdate();}
    if((actionToDo === "ListUpdateCatalogs" && props.Kind === "Catalogs") || (actionToDo === "ListUpdatePacks" && props.Kind === "Packs"))
      listUpdate();
    if(actionToDo !== "")
      dispatch(setActionToDo(""));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[actionToDo])
  const listUpdate = () =>
  {
    let dropContent = [];
    if(props.Kind === "Catalogs")
    {
      for(let i = 0;i<catalogList.length;i++)
      {
        if((catalogList[i]["title"]+"_"+catalogList[i]["_id"]).toLowerCase().includes(clientDictSign[props.Kind].toLowerCase()) && clientDictShow[props.Kind] !== undefined && !(clientDictShow[props.Kind].includes(catalogList[i]["title"]+"_"+catalogList[i]["_id"])))
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          dropContent.push(<React.Fragment key={i}><a className="dropdown-item" onClick={(e) => clickDropSearch(e)}>{catalogList[i]["title"]+"_"+catalogList[i]["_id"]}</a> </React.Fragment>);
      
          if(dropContent.length > 5)  
          break;
      }
    }
    else
    {
      for(let i = 0;i<catalogList.length;i++)
      {
        if((catalogList[i]).toLowerCase().includes(clientDictSign[props.Kind].toLowerCase()) && clientDictShow[props.Kind] !== undefined && !(clientDictShow[props.Kind].includes(catalogList[i])))
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          dropContent.push(<React.Fragment key={i}><a className="dropdown-item" onClick={(e) => clickDropSearch(e)}>{catalogList[i]}</a> </React.Fragment>);
      
          if(props.Kind === "Catalogs")
          {
            if(dropContent.length > 5)
              break;
          }
      }
    }
    setdropContentComp(dropContent);
  }
  
  return(<>
  <div className={!contantShowed ? "dropdown" : "dropdown  is-active"} onMouseEnter={(e) => {SetIsMouseover(true)}}  onMouseLeave={(e) => {SetIsMouseover(false)}}>
    <div className="dropdown-menu" id="dropdown-menu3" role="menu">
      <div className="dropdown-content" style={{"overflow-y": "scroll","height":"200px"}}>
        {dropContentComp}
      </div>
    </div>
  </div><br/>
  {props.Kind === "Catalogs" ?
    <CatalogToTags Catalogs={clientDictShow["Catalogs"]} isSign={true}/>
    :
    <PacksToTags Packs={clientDictShow["Packs"]} isSign={true}/>

  }
  </>);
}
export default DropDownSearch;