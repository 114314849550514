import React from 'react';

/**
 * AlertMsg
 * @return Alert Model when somthing wrong with server
 * @example <AlertMsg />
 */
const AlertMsg = () =>
{
  return(<div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
    <article className="message is-danger">
      <div className="message-header">
        <p>Error</p>
        <button className="delete" aria-label="delete" onClick={() => window.location.reload()}></button>
      </div>
      <div className="message-body">
        Server Error Please Refresh Page And Check The Server
      </div>
    </article>
    </div>
  </div>);
}
export default AlertMsg;
